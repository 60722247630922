<template>
	<div id="condition" v-if="menus[2]" v-html="menus[2].menuText">
		<!-- 投稿要求 -->
	</div>
</template>

<script>
	import {
		ProjectPage // 优秀稿件
	} from '@/request/api';
	export default {
		name: 'condition',
		data() {
			return {
				menus: ''
			}
		},
		created() {
			this.getProjectPage();
		},
		mounted() {},
		methods: {
			getProjectPage() {
				ProjectPage().then(res => {
					if (res.code == 200) {
						this.menus = res.data.menus
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style>
</style>